import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

const About = (onScrollEvent) => {
    const [vsab1InView, setVsab1InView] = useState(false);
    const [vsab2InView, setVsab2InView] = useState(false);

    const animateIds = [{divid:'vsab1', fn:setVsab1InView}, {divid:'vsab2', fn:setVsab2InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>
            <section
                className="mbr-section mbr-section__container article mbr-after-navbar"
                id="header3-0"
                style={{
                backgroundColor: 'rgb(67, 67, 67)',
                paddingTop: '120px',
                paddingBottom: '20px'
                }}
            >
                <div className="container">
                    <div className={vsab1InView ? "animated fadeInUp row" : "hidden row"} id="vsab1">
                        <div className="col-xs-12">
                            <h3 className="mbr-section-title display-2">About Us</h3>
                            <small className="mbr-section-subtitle">
                                If it moves...you need to track it!
                            </small>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="mbr-section article mbr-section__container"
                id="content1-1"
                style={{
                backgroundColor: 'rgb(255, 255, 255)',
                paddingTop: '20px',
                paddingBottom: '20px'
                }}
            >
                <div className="container">
                    <div className={vsab2InView ? "animated fadeInUp row" : "hidden row"} id="vsab2">
                        <div className="col-xs-12 lead">
                        <h4>If it moves, you need to track it...</h4>
                        <p>
                            <br />
                        </p>
                        <p>
                            <strong>Since 1982 Danby</strong> has been
                            supplying Fortune 1000 companies with the latest in automatic
                            identification solutions, including label printing systems and
                            data collection systems. Danby is a leading integrator of bar
                            code and RFID tracking solutions. Our knowledgeable sales and
                            technical staff, most of whom have over 20 years experience in
                            data collection, help our new and longtime customers take full
                            advantage of the latest marking and data capture technologies.{' '}
                            {/* <a href="/docs/Danby_At_A_Glance.pdf">Danby Brochure</a> */}
                        </p>
                        <p>
                            <br />
                        </p>
                        <a href="docs/DanbyOverview.pdf">Read the Danby Overview</a>
                        <p><br /></p>
                        <p>
                            <strong>Bar Code Labeling Supplies</strong>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            Danby also provides its customers with a wide array
                            of bar code printer labels, tags, and ribbons, as well as
                            encodable RFID Smart Tags. A Zebra Premier Partner, Danby
                            labeling experts can provide custom labeling solutions
                            utilizing the many Zebra specialty materials for any labeling
                            application.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <strong>Tracking Success</strong>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            Our focus on tracking, whether hard assets, documents, or
                            people, has allowed us to help many customers in the DoD space,
                            automotive, aerospace, healthcare and general manufacturing, to
                            show a return on investment in a very short time. Taking
                            advantage of industry-leading asset management products and
                            services that benefit your enterprise begins with a
                            collaborative program partner who understands your objectives,
                            helps develop your asset management strategy, and implements the
                            right solution for your operation. Whether you want to leverage
                            your investment in existing applications or acquire new
                            software, we work closely with you to evaluate your business
                            processes and recommend the most effective technology and
                            workflow to help achieve your goals.
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>Using a scalable approach, and encompassing cutting edge components from top manufacturers in our field, we can design a system that will:</p>
                        <p><br /></p>
                        <ul>
                            <li>Provide accurate inventory visibility and reduce out of stock conditions</li>
                            <li>Reduce the amount of time required to conduct inventory counts</li>
                            <li>Decrease the amount of time spent searching for specific assets</li>
                            <li>Increase asset-utilization rates</li>
                            <li>Comply with the many DoD, FDA and manufacturing mandates</li>
                            <li>Lower capital expenditures on new equipment</li>
                            <li>Improve employee satisfaction</li>
                        </ul>
                        <p><br /></p>
                        <p>Let us show your management team how we can help your company achieve its goals!</p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <strong>Danby and Covington Relationship</strong>
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                        Both <a href='https://covingtonbarcoding.com'>Covington Barcoding, Inc</a> and Danby Barcoding, LLC are pleased to be able to provide the same products and part numbers to the original Danby Group sales regions under one new owner. While Covington Barcoding, Inc. maintains the South East region originally outlined by the sale in 2015 the Danby Barcoding, LLC side of the company manages the more recently retained Danby Group deep south region, though both companies are able to support customers nationwide.
                        <p>
                            <br />
                        </p>
                        We continue to serve our existing customers and new business under one umbrella with unparalleled service and barcoding knowledge. I hope you find this information useful and if I can be of further assistance in the future please do not hesitate to reach out. 
                        </p>
                        <br />
                        <a href='/docs/CovingtonBarcodingIncandDanbyBarcodingLLCRelationship.pdf'>Read the letter from Mark Covington here.</a>
                        <p><br /></p>
                        <p><strong>Home Office</strong></p>
                        <p><br /></p>
                        <p>Danby Barcoding, LLC</p>
                        <p><br /></p>
                        <p>1800 Watmead Rd</p>
                        <p>Kernersville, NC 27284</p>
                        <p>Toll Free: 800-262-2629</p>
                        <p>Toll: 770-416-9844</p>
                        <p>Fax: 770-416-9845</p>
                        <p>&nbsp;</p>
                        <p><br /></p>
                        <p>General Information Inquiries:</p>
                        <p><a href="mailto:info@danbybarcoding.com">info@danbybarcoding.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default About;
