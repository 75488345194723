import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

function Tscprinters({onScrollEvent}) {

    const [vstsc1InView, setVstsc1InView] = useState(false);
    const [vstsc2InView, setVstsc2InView] = useState(false);
    const [vstsc3InView, setVstsc3InView] = useState(false);
    const [vstsc4InView, setVstsc4InView] = useState(false);
    const [vstsc5InView, setVstsc5InView] = useState(false);

    const animateIds = [{divid:'vstsc1', fn:setVstsc1InView}, {divid:'vstsc2', fn:setVstsc2InView}, {divid:'vstsc3',fn:setVstsc3InView}, {divid:'vstsc4', fn:setVstsc4InView},{divid:'vstsc5',fn:setVstsc5InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>

            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0"
                style={{backgroundColor: "rgb(67, 67, 67)", paddingTop: "120px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className={vstsc1InView ? "animated fadeInUp row" : "hidden row"} id="vstsc1">
                        <div className="col-xs-12">
                            <h3 className="mbr-section-title display-2">TSC</h3>
                            <small className="mbr-section-subtitle"> Bar Code Printers</small>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content1-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vstsc2InView ? "animated fadeInUp row" : "hidden row"} id="vstsc2">
                        <div className="col-xs-12 lead">
                            <p>If you're considering upgrading your company's bar code printers, but budgets are tight and you don't
                                want to sacrifice quality, it's time you looked at Danby's newest partner: TSC Printers.
                            </p>
                            <p><br/></p>
                            <p><strong>Not just another “me too” printer company</strong>, TSC has been in the business for 25 years
                                and has designed many of the better known printers for several big name manufacturers. With years of
                                experience and design savvy, TSC combines the highest production standards with the best printer
                                features, lowest prices, and longest warranties – 2 YEARS, in fact. <strong>TSC printers always come
                                    standard with the major printer programming languages built right in – no need to reformat your
                                    label designs!</strong></p><br></br>
                        </div>
                    </div>
                </div>

            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-0"
                style={{backgroundColor: "rgb(255, 255, 255)"}}>



                <div className={vstsc3InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} id="vstsc3">
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/ttp-2410mt-series-1-square1final-600x600-48.jpg"
                                        className="card-img-top" alt='Easy-to-Use Color Displays'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Easy-to-Use Color Displays</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/ttp-2610mt-21-600x600-77.jpg" className="card-img-top" alt='Affordable 6.6"-8.6" Wide Web'/>
                                </div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Affordable 6.6"-8.6" Wide Web</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/mx240-printer-2trans1-600x600-30.jpg"
                                        className="card-img-top" alt='24/7 Mission Critical'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">24/7 Mission Critical</h5>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-1"
                style={{backgroundColor: "rgb(255, 255, 255)"}}>



                <div className={vstsc4InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} id="vstsc4">
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/tx200-series-1squarefinal-600x600-92.jpg"
                                        className="card-img-top" alt='Desktops w/ Color Displays'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Desktops w/ Color Displays</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/alpha-4l-3square-600x600-19.jpg" className="card-img-top" alt='Rugged 3", 4" Mobile Printers'/>
                                </div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Rugged 3" &amp; 4" Mobile Printers</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/tdp-225-a-resize1-600x600-18.jpg"
                                        className="card-img-top" alt='Inexpensive 2" - 4" Direct Thermal Desktops'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Inexpensive 2" - 4" Direct Thermal Desktops</h5>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content7-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vstsc5InView ? "animated fadeInUp row" : "hidden row"} id="vstsc5">
                        <div className="col-xs-12 col-md-4 lead">
                            <p>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>As you have come
                                        to expect from Danby Barcoding, we are continually exploring the options in the marketplace
                                        to make sure we're providing our customers with the best-in-className hardware, software, and
                                        printing media.</span></font>
                            </p>
                            <div>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Here are just a
                                        couple of examples we are very impressed with…</span></font>
                            </div>
                            <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                            <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><strong>TSC's MH240 Series printers are
                                        economical and fully loaded:</strong></span><br/></div>
                            <div>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                </font>
                            </div>
                            <ul>
                                <li>
                                    <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>All metal
                                            construction</span></font>
                                </li>
                                <li>
                                    <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Thermal and
                                            Thermal Transfer printing modes</span></font>
                                </li>
                                <li>
                                    <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>4.09” label
                                            width max</span></font>
                                </li>
                                <li>
                                    <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Direct
                                            thermal &amp; thermal transfer printing modes</span></font>
                                </li>
                                <li>
                                    <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Quadruple
                                            connectivity: internal Ethernet, USB 2.0, Serial and Parallel</span></font>
                                </li>
                                <li>
                                    <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Two-year
                                            warranty</span></font>
                                </li>
                            </ul>
                            <div>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                </font>
                            </div>
                            <div>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                </font>
                            </div>
                            <div>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                </font>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 lead"><strong>Need a lower volume, space-saving desktop printer? You can't do
                                better than TSC's TX200 which comes standard with: </strong>&nbsp;<div><br/></div>
                            <ul>
                                <li>Rugged, double-wall construction</li>
                                <li>Thermal AND Thermal Transfer printing modes</li>
                                <li>4.09” label width max</li>
                                <li>6 inches per second print speed</li>
                                <li>Quadruple connectivity: internal Ethernet, USB 2.0, Serial and Parallel</li>
                                <li>Two-year warranty</li>
                            </ul>
                            <div><br/></div>
                            <div><br/></div>
                            <div><br/></div>
                            <div><strong>About TSC:</strong> In business since 1991, TSC has supplied more than 3 million label
                                printers to the auto-ID market. With an emphasis on exceptional quality, competitive pricing, and
                                many standard features that are priced as “options” on competitors' similar models, it's no wonder
                                TSC is turning heads throughout the industry.</div>
                        </div>
                        <div className="col-xs-12 col-md-4 lead">
                            <h5><strong>TSC Resources</strong></h5>
                            <p><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></p>
                            <p><a href="docs/TSC MH241 & MH261 6 inch Printer Series">TT MH241 & MH261 6 inch Printer Series</a>
                            </p>
                            <p><br/></p>
                            <p><a href="docs/TSC MX240P 4 inch Industrial Printer.pdf">TSC MX240P 4 inch Industrial Printer</a></p>
                            <p><br/></p>
                            <p><a href="docs/TSC MX241P Industrial Printer.pdf">TSC MX241P Industrial Printer</a></p>
                            <p><br/></p>
                            <p><a href="docs/TSC TDP-247 Direct Thermal 4 inch Desktop Printer.pdf">TSC TDP-247 Direct Thermal 4 inch
                                    Desktop Printer</a></p>
                            <p><br/></p>
                            <p><a href="docs/TSC TTP-247 4 inch Desktop Printer.pdf">TSC TTP-247 4 inch Thermal Transfer Desktop Printer</a></p>
                            <p><br/></p>
                            <p><a href="docs/TX200 Desktop Series.pdf">TX200 Desktop Series</a></p>
                            <p><br /></p>
                            <p><a href="docs/Danby-Cleaning_your_Industrial_Printer.pdf">Cleaning your Industrial Printer</a></p>
                        </div>
                    </div>
                </div>

            </section>   

        </>
    )
}

export default Tscprinters;