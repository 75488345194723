import React, {useEffect, useState} from 'react';
import checkIfInView from '../utils/checkInView';

function Main({onScrollEvent}) {

    const [vsm1InView, setVsm1InView] = useState(false);
    const [vsm2InView, setVsm2InView] = useState(false);
    const [vsm3InView, setVsm3InView] = useState(false);
    
    const animateIds = [{divid:'slider-0', fn:setVsm1InView}, {divid:'features2-0', fn:setVsm2InView}, {divid:'vsm3',fn:setVsm3InView}];

    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])

    return (
        <>
            <section className={vsm1InView ? "animated fadeInUp mbr-slider mbr-section mbr-section__container carousel slide mbr-section-nopadding mbr-after-navbar" : "hidden mbr-slider mbr-section mbr-section__container carousel slide mbr-section-nopadding mbr-after-navbar"} data-ride="carousel" data-keyboard="false" data-wrap="true" data-pause="false" data-interval="8000" id="slider-0">
                <div className="container boxed-slider" style={{paddingBottom: 0}}>
                    <div>
                    <div className="carousel-inner" role="listbox">
                        <div className="mbr-section mbr-section-hero carousel-item dark center active" data-bg-video-slide="false">
                        <div className="mbr-table-cell">
                            <div className="container-slide">
                            <img src="assets/images/DanbyBarcodingBanner.png" alt="slide-one" />
                            {/* Add more slides as needed */}
                            </div>
                        </div>
                        </div>
                        {/* Add more carousel items here */}
                    </div>
                    </div>
                </div>
            </section>
            <section className={vsm2InView ? "animated fadeInUp mbr-cards mbr-section mbr-section-nopadding" : "hidden mbr-cards mbr-section mbr-section-nopadding"} id="features2-0">
                <div id="indsec2div1" className="mbr-cards-row row striped">
                    {/* Card 1 */}
                    <div id="indsec2div2" className="mbr-cards-col col-xs-12 col-lg-3">
                        <div className="container">
                            <div id="vsm3" className={vsm3InView ? "animated card card-block" : "hidden card card-block"}>
                                <div className="card-img">
                                    <div className="etl-icon icon-printer mbr-iconfont mbr-iconfont-features2"></div>
                                </div>
                                <div className="card-block">
                                    <h1 className="card-title">Printers and Services</h1>
                                    <h2 className="card-subtitle">TSC bar code, Zebra bar code &amp; RFID printer/encoders</h2>
                                    <p className="card-text">Since 1982, barcode printers have been the cornerstone of Danby auto-ID solutions.  Our knowledgeable sales staff can help your company choose the right printer for your environment and applications.
                                    </p>
                                    <div className="card-btn"><a href="zebraprinters.php" className="btn btn-primary">MORE</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div id="indsec2div5" className="mbr-cards-col col-xs-12 col-lg-3">
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img">
                                    <div className="etl-icon icon-ribbon mbr-iconfont mbr-iconfont-features2"></div>
                                </div>
                                <div className="card-block">
                                    <h1 className="card-title">Labels, Tags and Ribbons</h1>
                                    <h2 className="card-subtitle">Bar code labels, ribbons, RFID Smart Tags and custom media</h2>
                                    <p className="card-text">If it moves, you need to label or tag it and we can show you how. We maintain a large and varied inventory of the most innovative labeling materials, including Genuine Zebra Supplies.</p>
                                    <div className="card-btn"><a href="label.php" className="btn btn-primary">MORE</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add more cards as needed */}
                </div>
            </section>
        </>
    );
}

export default Main;