import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

function Epsonprinters({onScrollEvent}) {

    const [vsecw1InView, setVsecw1InView] = useState(true);
    const [vsecw2InView, setVsecw2InView] = useState(true);
    const [vsecw3InView, setVsecw3InView] = useState(true);

    const animateIds = [{divid:'vsecw1', fn:setVsecw1InView}, {divid:'vsecw2', fn:setVsecw2InView}, {divid:'vsecw3',fn:setVsecw3InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>

            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0"
                style={{backgroundColor: "rgb(67, 67, 67)", paddingTop: "120px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className={vsecw1InView ? "animated fadeInUp row" : "hidden row"} id="vsecw1">
                        <div className="col-xs-12">
                            <h3 className="mbr-section-title display-2">Epson Colorworks</h3>
                            <small className="mbr-section-subtitle"> Printers</small>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content1-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vsecw2InView ? "animated fadeInUp row" : "hidden row"} id="vsecw2">
                        <div className="row" style={{textAlign: 'center'}}>
                            <strong><font size="5" color="#2a2a2a">Do More With Epson ColorWorks Ink-Jet Color Label Printers</font></strong>
                        </div>
                        <p><br /></p>
                        <div className="row">
                            <div className='col-xs-6'>
                                <img className='img-fluid' style={{objectFit:"contain"}} src="assets/images/colorworks-c7500-c4000-c6500-c6000-family-image-cpd-62904-final_2.jpg" alt="Epson Printers" />
                            </div>
                            <div className="col-xs-6 lead">
                            <font size="4"><strong>ColorWorks Printers for GHS, Industrial, Healthcare &amp; Prime Labeling.</strong></font><br/>The first printer series specifically designed as a color upgrade to black-and-white thermal transfer printers, the ColorWorks Series of inkjet printers deliver on-demand, 4" and 8" color labels at a comparable price. Engineered for mission-critical applications, these reliable printers speed through labels at up to 5" per second. 3 Featuring up to 1200 dpi resolution produces crisp images comparable to pre-printed labels. Compatible with ZPL II, SAP® and more, and built to accommodate remote printer management, these versatile printers offer seamless integration with your existing workflow.
                            <a href="docs/DANBY-ColorWorks_2024_Line_Guide.pdf"> Download the Danby Epson Colorworks Specsheet</a>.
                            <img className='img-fluid' style={{objectFit:"contain"}} src="assets/images/epson-profocus-platinum-logo-72_orig.png" alt="Epson Printers" />
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="row">
                            <div className="col-xs-12" style={{textAlign: "center", color: "rgb(255, 255, 255)", backgroundColor: "rgb(162, 23, 16)", fontSize: "1.5rem", minHeight: "5rem", display: "grid", alignItems: "center"}}>
                                <strong>CONTACT US TODAY FOR YOUR COLORWORKS INK AND INK JET LABELS</strong>
                            </div>
                        </div>
                        <p><br /></p>
                        <hr style={{backgroundColor: "rgba(0,0,0,0.13)"}}></hr>
                    </div>
                </div>

            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-0"
                style={{backgroundColor: "rgb(255, 255, 255)"}}>
                <div className={vsecw3InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} style={{padding: "0 15% 0 15%"}} id="vsecw3">
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-block">
                                        <div className="card-img"><img src="assets/images/chem-labels-digital-label_2.jpg"
                                                className="card-img-top" alt='Chemical Label Printing Solutions'/></div>
                                        <div className="card-block">
                                            <h2 className="card-title">Chemical Label Printing Solutions</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><br /></p>
                            <div className="row lead">
                                <div className="col-xs-12" style={{textAlign:"left"}}><strong><font size="3">Why Choose Epson Label Printers For all your chemical, hazardous material and GHS needs?</font></strong><ol><li><strong>Reliable Performance</strong> - Renowned Epson printhead, ink and design technologies yield unmatched quality and durability.</li><li><strong>Superior Image Quality</strong> - PrecisionCore® technology delivers vibrant precise colors, smooth gradations, ultra‑sharp text and accurate bar codes.</li><li><strong>Total Product Range</strong> - Epson is the only company offering a complete line from small desktop units to full industrial presses.</li><li><strong>Low Operating Cost</strong> - Extreme long‑life head technology, low ink cost and high speeds provide significant cost savings.</li><li><strong>Durable Labels</strong> - Water resistant labels won’t smudge, smear or fade. They're BS‑5609 certified and chemical resistant</li></ol> Requirements for Meeting OSHA Standards<br/>BS5609 is one of the most common benchmarks for certifying durability. Epson was the first to create a BS5609 certified inkjet solution and continues to offer superior printers for on demand, durable, color label printing that meets the new OSHA Hazard Communication Requirement.&nbsp; <strong><a href="https://mediaserver.goepson.com/ImConvServlet/imconv/bdb263810e386e22e0da4d80776ba9242b5d576b/original?use=banner" target="_blank" rel="noreferrer">EPSON GHS PRINTING </a></strong></div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-block">
                                        <div className="card-img"><img src="assets/images/fresh-food-cw-printers-on-demand-copy_2.jpg" className="card-img-top" alt='Primary Direct Consumer Label Printing'/>
                                        </div>
                                        <div className="card-block">
                                            <h2 className="card-title">Primary Direct Consumer Label Printing</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><br /></p>
                            <div className="row lead">
                                <div className="col-xs-12" style={{textAlign: 'left'}}>Save time and money by printing only the labels you need, when you need them. Epson ColorWorks color label printers deliver the flexibility and reliability you need to decrease total label printing operations costs and reduce waste.<br/><br/>• Elevate brand appeal and perceived quality<br/>• Boost sales and customer satisfaction<br/>• Increase distribution productivity and accuracy<br/><br/>Epson Colorworks offers on-demand commercial label printing.<ul><li><strong>Increase Flexibility</strong> — immediately address any printing errors in house</li><li><strong>Reduce Total Costs</strong> — less inventory on‑hand frees storage space &amp; omits the need for large label runs to amortize setup costs</li><li><strong>Save Time &amp; Reduce Costs</strong> — eliminate the need to monitor, plan and re‑order pre‑printed stock</li></ul></div>
                            </div>

                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-block">
                                        <div className="card-img"><img src="assets/images/cw-c4000-lifestyle-08_2.jpg"
                                                className="card-img-top" alt='Retail and Food & Beverage Labeling'/></div>
                                        <div className="card-block">
                                            <h2 className="card-title">Retail and Food &amp; Beverage Labeling</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><br /></p>
                            <div className="row lead">
                                <div className="col-xs-12" style={{textAlign: 'left'}}>Fast, full‑color, high‑volume, heavy production labels for busy, fresh food production. Epson's robust label printers provide easy‑to‑change, individual cartridges for efficient use of ink. With on‑demand flexibility and minimal maintenance, you can produce full‑color labels without increasing your per‑label cost.<br/><br/>Available in Matte or Gloss, delivers powerful on-demand commercial color Label Printing.<br/><br/>This versatile label printer features high-performance PrecisionCore® technology and is engineered for reliability — it delivers crisp, photo-quality color labels on demand, at up to 1200 dpi resolution. <br/><br/>The Epson Colorworks printers are compatible with ZPL II®, SAP®, major middleware and more, allowing for seamless integration with your existing workflow. And, with iOS® and Android™ software development tools, remote printer management capability and optional Wi-Fi® connectivity, you can help maximize the mobility and efficiency of your business processes.<ul><li>Designed for reliability; engineered for demanding applications</li><li>Crisp, photo-quality images</li><li>Easy integration with ZPL II, SAP, major middleware and more</li><li>Wi-Fi capability</li></ul><br/><br/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Epsonprinters;