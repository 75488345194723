import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

function Labeling({onScrollEvent}) {

    const [vsl1InView, setVsl1InView] = useState(false);
    const [vsl2InView, setVsl2InView] = useState(false);
    const [vsl3InView, setVsl3InView] = useState(false);
    const [vsl4InView, setVsl4InView] = useState(false);
    const [vsl5InView, setVsl5InView] = useState(false);

    const animateIds = [{divid:'vsl1', fn:setVsl1InView}, {divid:'vsl2', fn:setVsl2InView}, {divid:'vsl3',fn:setVsl3InView}, {divid:'vsl4', fn:setVsl4InView},{divid:'vsl5',fn:setVsl5InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>

            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0" style={{backgroundColor: "rgb(67, 67, 67", paddingTop: "120px", paddingBottom: "20px"}}>
                <div className="container">
                    <div id="vsl1" className={vsl1InView ? "animated fadeInUp row" : "hidden row"}>
                        <div className="col-xs-12">
                            <h3 className="mbr-section-title display-2">Labeling</h3>
                            <small className="mbr-section-subtitle">If it moves, you need to label or tag it and we can show you how!</small>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content1-0" style={{backgroundColor: "rgb(255, 255, 255", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div id='vsl2' className={vsl2InView ? "animated fadeInUp row" : "hidden row"}>
                        <div className="col-xs-12 lead"><p>Danby Barcoding is your single source for direct thermal and thermal transfer consumables. We maintain a large and varied inventory of the most innovative labeling materials. We know your marking needs are mission critical - we pride ourselves on fast, knowledgeable response times.&nbsp;</p></div>
                    </div>
                </div>

            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-0" style={{backgroundColor: "rgb(255, 255, 255)"}}>



                <div id='vsl3' className={vsl3InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"}>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/zd420-printer-mediacube-600x600-60.png" className="card-img-top" alt='Desktop Printer Supplies'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Desktop Printer Supplies</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/qln220-w-mediacube-600x600-76.png" className="card-img-top" alt='Mobile Printer Supplies'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Mobile Printer Supplies</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/tsc-mx240-printercube-600x600-74.jpg" className="card-img-top" alt='Tabletop Printer Supplies'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Tabletop Printer Supplies</h5>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-1" style={{backgroundColor: "rgb(255, 255, 255)"}}>



                <div id='vsl4' className={vsl4InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"}>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/wristband-printer-suppliescube-600x600-69.png" className="card-img-top" alt='Zebra Wristband Supplies'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Zebra Wristband Supplies</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/prime-label-best-assortment-shotcube-600x600-85.jpg" className="card-img-top" alt='Pre-printed Full Color Labels'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Pre-printed Full Color Labels</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/zipship-supplies-group-600x600-24.jpg" className="card-img-top" alt='Everything you need'/></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Everything you need!</h5>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content7-0" style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div id='vsl5' className={vsl5InView ? "animated fadeInUp row" : "hidden row"}>
                        <div className="col-xs-12 col-md-4 lead">
                            <p><strong>Bar code labels and ribbons</strong>&nbsp;</p>
                            <p>Providing both Zebra-branded product as well as our own private labeling solutions, we offer stock
                                and custom labeling and tagging supplies in an endless variety of materials, sizes, adhesives,
                                colors, and preprints. We can fill many specialized needs such as multi-part labels, removable
                                product ID labels, tamper-evident fixed asset labels. Danby Barcoding has over 30 years of labeling
                                expertise in manufacturing, retail, service and healthcare.</p>
                            <p><br/></p>
                            <p>If one of our hundreds of in-stock labels or tags does not suit your needs, we can provide&nbsp;
                                <a href data-toggle="modal" data-target="#reqcustom" style={{cursor: "pointer"}} className="text-danger"><strong>Custom Media</strong></a> in a wide variety of innovative
                                materials.&nbsp;</p>
                            <p
                                style={{paddingTop: "22px", paddingLeft: "5px", height: "70px", marginTop: "10px", background: "transparent url(assets/images/scanButton.png) no-repeat 0 0"}}>
                                <a href data-toggle="modal" data-target="#reqcustom" style={{cursor: "pointer", fontSize: "medium", color: "brown"}}>Request Custom/Stock Label</a></p>
                            <p><br/></p>
                            <p><a href="docs/Printhead_Protection_Brochure.pdf" className="text-danger"><strong>Zebra Complete
                                        Consumables Program</strong></a>: If your company commits to using only Genuine Zebra
                                Supplies in a specific Zebra printer (existing or new printer), Zebra will provide free replacement
                                printheads. Ask us about Zebra's FREE PRINTHEADS program today!</p>
                        </div>
                        <div className="col-xs-12 col-md-4 lead">
                            <p><strong>Labeling for Healthcare</strong></p>
                            <p>Your experienced Danby rep can help you navigate the many unique aspects of labeling for healthcare applications.  From cryogenic labeling to sterilization and UDI, look to Danby for solutions that provide long-lasting protection and readability and fulfill many FDA mandates.  See our <a href="docs/DanbyLaboratoryLabeling.pdf" className="text-danger"><strong>Labratory Labelling offerings</strong></a>``.
                            </p>
                            <p><br/></p>
                            <p><strong>Compliance Labeling Made Easy </strong>Danby Barcoding provides stock and custom
                                labels and tags for its clients who have compliance requirements. Government mandates such
                                as those issued from UL, Military (such as MIL-STD or UID), the FDA (such as UDI and
                                safe-for-food labels) and many more. <em>Compliance could not be easier!</em></p>
                        </div>
                        <div className="col-xs-12 col-md-4 lead">
                            <h5><strong>Labeling Resources</strong></h5>
                            <p><a href="docs/Zebra_Supplies_Selector_Guide_2021.pdf">Zebra Supplies Selector Guide 2021</a></p>
                            <p><a href="docs/Laboratory_Labeling_Solutions.pdf">Laboratory Labeling Solutions</a></p>
                            <p><a href="docs/UDI_Labeling_Solutions.pdf">UDI Labeling Solutions</a></p>
                            <p><a href="docs/Medical_Device_Mfg-UDI_Traceability.pdf">Medical Device Mfg - UDI Tracebility</a></p>
                            <p><a href="docs/Electronics_Labeling_Fact_Sheet.pdf">Electronics Labeling Fact Sheet</a></p>
                            <p><a href="docs/DANBY_Supplies_Flyer_HI-RES.pdf">Danby Supplies</a></p>
                            <p><br/></p>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Labeling;