import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

function Zebraprinters({onScrollEvent}) {

    const [vszp1InView, setVszp1InView] = useState(false);
    const [vszp2InView, setVszp2InView] = useState(false);
    const [vszp3InView, setVszp3InView] = useState(false);
    const [vszp4InView, setVszp4InView] = useState(false);
    const [vszp5InView, setVszp5InView] = useState(false);

    const animateIds = [{divid:'vszp1', fn:setVszp1InView}, {divid:'vszp2', fn:setVszp2InView}, {divid:'vszp3',fn:setVszp3InView}, {divid:'vszp4', fn:setVszp4InView},{divid:'vszp5',fn:setVszp5InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>

            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0" style={{backgroundColor: "rgb(67, 67, 67)", paddingTop: "120px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className={vszp1InView ? "animated fadeInUp row" : "hidden row"} id='vszp1'>
                        <div className="col-xs-12">
                            <a href="docs/zt600-series-brochure.pdf">
                                <img src="assets/images/Danby-468x60-ZT600.jpg" style={{maxWidth:"100%"}} alt='header'/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content1-0" style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vszp2InView ? "animated fadeInUp row" : "hidden row"} id='vszp2'>
                        <div className="col-xs-12 lead"><p>Partners with Zebra since 1982, Zebra printers have been integral to the Danby solution. Our knowledgeable technicians and sales staff receive ongoing, factory training throughout the year to learn the latest in Zebra products and technologies. From light duty applications in health and retail, to 24/7 mission-critical manufacturing environments, there is a Zebra printer to fit any labeling need.&nbsp;</p><p><br/></p><p>Zebra printers are famous for reliability - Danby Barcoding often sees printers still working in the field, after 10 or more years of heavy use. Flexible connectivity options are another reason Zebra printers are the number one selling brand...no one wants connection hassles when they're moving printers around. And for ease-of-use, nothing can beat a Zebra printer.</p><p><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></p><p><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><p className="">Featured this month is the newest and most exciting printer series to come along in quite a while.  Zebra engineers have spent several years working on the new design of their High-Performance line, the all new <em className=""><a href="docs/zt600-series-brochure.pdf" className="text-danger">ZT600 series</a></em>.</p><p className=""><br/></p><div className="row"><div className="col-xs-8"><p className="">
                        The new printers boast a super-fast Cortex 800Mhz processor and are loaded with very user-friendly features:</p><ul><li className="">Intuitive 3.5" multi-lingual, high-res color LCD display</li><li className="">An illuminated media cabinet and color-coded label/ribbon loading path</li><li className="">Micro consistent label printing, even as small as 3mm</li><li className="">Field-installable options such as cutter or RFID encoder</li><li className="">Simple printhead and platen removal and replacement</li></ul><p className=""></p><br/><p className="">The ZT610 has a maximum 4.09” print width and replaces the best-selling 110Xi4 line.</p><p className=""><a href="https://youtu.be/okNJy0OEPl8" className="text-danger" style={{backgroundColor:"gold"}} target="_video"><strong>Click here for a brief video on the new series</strong></a></p></div><div className="col-xs-4" style={{textAlign: "center"}}><a href="docs/ZT600_Series_Comparison_Guide.pdf" className="text-danger">Compare with the Xi4 Series<img src="assets/images/zt610-620-side-by-side-capture-588x446.jpg" alt="The All-New ZT600 Series" title="The All-New ZT600 Series" style={{maxWidth:"100%"}}/></a></div></div></span><br/></p></div>
                    </div>
                </div>

            </section>







            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-0" style={{backgroundColor: "rgb(255, 255, 255);"}}>



                <div className={vszp3InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} id='vszp3'>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><a href="docs/zt600-series-brochure.pdf"><img src="assets/images/zt600-series-600x299.jpg" className="card-img-top" alt="The All-New ZT600 Series" title="The All-New ZT600 Series" style={{maxWidth: "120%"}}/></a></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle" style={{marginTop: "1.3rem"}}>The All-New ZT600 Series Printers</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><a href="docs/zt510-printer-brochure.pdf"><img src="assets/images/zt510-printer-side-view-548x440.jpg" className="card-img-top" style={{maxWidth:"80%"}} alt="ZT510" title="New from Zebra: ZT510 Economy Industrial"/></a></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle" style={{paddingTop: "2rem"}}>ZT510 Economy Industrial</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><a href="docs/ZT200-datasheet-en-us.pdf"><img src="assets/images/zebra-zt200-pair-600x600-83.jpg" className="card-img-top" alt="Zebra ZT200 Series" title="Zebra ZT200 Series"/></a></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">Zebra ZT200 Series</h5>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-1" style={{backgroundColor: "rgb(255, 255, 255);"}}>



                <div className={vszp4InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} id='vszp4'>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><a href="docs/ZD420_Desktop_Printer_Brochure_2017.pdf"><img src="assets/images/zd420-620-desktop-printers-2017-553x274.png" className="card-img-top" alt="New ZD420 & ZD620 Desktop Printers" title="New ZD420 & ZD620 Desktop Printers"/></a></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle" style={{paddingTop: "3rem"}}>New ZD421 &amp; ZD621 Desktop Printers</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><a href="docs/ZD500R_Compact_RFID_Printer_2014.pdf"><img src="assets/images/zd500r-compact-rfid-printer-600x600-55.jpg" className="card-img-top" alt="ZD500R" title="ZD500R"/></a></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">ZD500R Compact RFID Printer</h5>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><a href="docs/ZT400_Brochure.pdf"><img src="assets/images/zt400-printer-600x600-90.jpg" className="card-img-top" alt="ZT400" title="ZT400"/></a></div>
                                <div className="card-block">

                                    <h5 className="card-subtitle">ZT400 Industrial Printer</h5>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="zebraprinters-content7-0" style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vszp5InView ? "animated fadeInUp row" : "hidden row"} id='vszp5'>
                        <div className="col-xs-12 col-md-8 lead">
                            <h5><strong>Printer Trade-in Opportunity</strong></h5>
                            <p><br/></p>
                            <p><em> For detailed Zebra printer specifications, please see the Printer Resources section to the
                                    right. And don't forget to take advantage of the 2021 Go-Zebra Trade-in Program to receive up to
                                    $500 cash for your old Zebra printers, as well as other major brands! <u> Fill out the Contact
                                        Us form at the bottom of this page for more information!</u></em></p>
                            <p><br/></p>
                            <p><strong>Labels, Tags and Ribbons for your Thermal Bar Code Printer - Any Make, Any Model</strong></p>
                            <p><br/></p>
                            <p>We are your single source for direct thermal and thermal transfer consumables. Danby Barcoding has
                                over 30 years of labeling expertise in manufacturing, retail, healthcare and service industries.
                                Providing both Zebra-branded product as well as our own private labeling solutions, we offer stock
                                and custom labeling and tagging supplies in an endless variety of materials, sizes, adhesives,
                                colors, and preprints. Multi-part labels, removable product ID labels, tamper-evident fixed asset
                                labels - Danby Barcoding has over 30 years of labeling expertise in manufacturing, retail, service
                                and healthcare.&nbsp;</p>
                        </div>
                        <div className="col-xs-12 col-md-4 lead">
                            <h5><strong>Printer Resources</strong></h5>
                            <p><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></p>
                            <p><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}></span><br/></p>
                            <p><a href="docs/Printhead_Protection_Brochure.pdf">Printhead Protection Brochure</a></p>
                            <p><a href="docs/Danby-Cleaning_your_Industrial_Printer.pdf">Cleaning your Industrial Printer</a></p>
                            <p><strong><br/></strong></p>
                            <p><strong>Industrial Printers</strong><br/></p>
                            <p><span style={{lineHeight: "1.5"}}><a href="docs/ZM-series-datasheet-en-us.pdf">ZT410/420
                                        Series Brochure</a></span><br/></p>
                            <p><a href="docs/ZT200-datasheet-en-us.pdf">ZT200 Series Brochure</a></p>
                            <p><a href="docs/zt510-printer-brochure.pdf">ZT510 Printer Brochure</a></p>
                            <p><a href="docs/zt600-series-brochure.pdf">ZT600 Series Brochure</a></p><p><br/></p><p><strong>RFID Printer/Encoders</strong></p><p><a href="
                                    docs/Zebra_ZD500R_Desktop_RFID_Printer_Brochure.pdf">ZD500R Desktop RFID Printer</a> </p>
                                    <p><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><a
                                            href="docs/ZT600-RFID-Printers-Encoders.pdf">ZT600 Series RFID Printers &amp;
                                            Encoders</a></span><br/></p>
                            <p><a href="docs/p4t-rp4t-en-us.pdf">RP4T Passive RFID Printers</a></p>
                            <p><br/></p>
                            <p><strong>Desktop Printers</strong></p>
                            <p><span style={{lineHeight: "1.5"}}><a href="docs/ZD420_Desktop_Printer_Brochure_2017.pdf">ZD421 / 621
                                        Series</a></span><br/></p>
                            <p><a href="docs/GC420-datasheet-en.pdf">GC Series</a></p>
                            <p><a href="docs/HC-100-wristband-datasheet-en-us.pdf">HC100 Wristband Printer</a></p>
                            <p><a href="docs/Zebra_ZD500R_Desktop_RFID_Printer_Brochure.pdf">Zebra ZD500R Desktop RFID Printer</a>
                            </p>
                            <p><a href="docs/Zebra_Desktop_Printer_Comparison_Matrix.pdf">Desktop Comparison Matrix</a></p>
                            <p><br/></p>
                        </div>
                    </div>
                    </div>

            </section>
            <section className="mbr-section article mbr-section__container" id="zebraprinters-content1-0" style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "0px", paddingBottom: "20px"}}>
            </section>        

        </>
    )
}

export default Zebraprinters;