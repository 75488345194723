import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from './components/Menu';
import Main from './components/Main';
import Footer from './components/Footer';
import Labeling from './components/Labeling';
import Zebraprinters from './components/Zebraprinters';
import Tscprinters from './components/Tscprinters';
import Tharoprinters from './components/Tharoprinters';
import Epsonprinters from './components/Epsonprinters';
import Laminators from './components/Laminators';
import Software from './components/Software';
import EasyLabel from "./components/Easylabel";
import About from './components/About';

function App() {
  const [onscroll, setOnscroll] = useState(0);
  useEffect(() => {
    const handleScroll = (event) => {
      setOnscroll(window.scrollY);
    }
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    }
  },[]);
  return (
    <>
      <Menu/>
      <Router>
        <Routes>
          <Route path="/" element={<Main onScrollEvent={onscroll}/>} />
          <Route path="/label" element={<Labeling onScrollEvent={onscroll}/>} />
          <Route path="/zebraprinters" element={<Zebraprinters onScrollEvent={onscroll}/>} />
          <Route path="/tscprinters" element={<Tscprinters onScrollEvent={onscroll}/>} />
          <Route path="/tharoprinters" element={<Tharoprinters onScrollEvent={onscroll}/>} />
          <Route path="/epsonprinters" element={<Epsonprinters onScorllEvent={onscroll}/>} />
          <Route path="/laminators" element={<Laminators onScrollEvent={onscroll}/>} />
          <Route path="/software" element={<Software onScrollEvent={onscroll}/>} />
          <Route path="/easylabel" element={<EasyLabel onScrollEvent={onscroll}/>} />
          <Route path="/about" element={<About onScrollEvent={onscroll}/>} />
        </Routes>
      </Router>
      <Footer onScrollEvent={onscroll}></Footer>
    </>
  );
}

export default App;
