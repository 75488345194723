import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

const Software = (onScrollEvent) => {
    const [vssf1InView, setVssf1InView] = useState(false);
    const [vssf2InView, setVssf2InView] = useState(false);
    const [vssf3InView, setVssf3InView] = useState(false);

    const animateIds = [{divid:'vssf1', fn:setVssf1InView}, {divid:'vssf2', fn:setVssf2InView}, {divid:'vssf3',fn:setVssf3InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>
            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0"
                style={{ backgroundColor: 'rgb(67, 67, 67)', paddingTop: '120px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf1InView ? "animated fadeInUp row" : "hidden row"} id="vssf1">
                        <div className="col-xs-12">
                        <h3 className="mbr-section-title display-2">BarTender</h3>
                        <small className="mbr-section-subtitle">Ties it all together</small>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="page10-content1-0"
                style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf2InView ? "animated fadeInUp row" : "hidden row"} id="vssf2">
                        <div className="col-xs-12 lead">
                            <center><img src="assets/images/bartendernewlogo.png" alt="BarTender Logo" /></center>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="software-content7-0"
                style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf3InView ? "animated fadeInUp row" : "hidden row"} id="vssf3">
                        <div className="col-xs-12 col-md-5 lead">
                        <h5><span style={{ color: 'inherit', fontSize: '1.25rem', lineHeight: '1.1' }}>Label Design Software</span><br />
                        </h5>
                        <p><strong>Seagull Scientific's BarTender Software - the World's Most Trusted Labeling, Bar Code, and
                                    RFID Design Software</strong></p>
                        <p>Our customers often ask us “which is the best label design package?” Without hesitation, we always
                            recommend BarTender. Seagull Scientific has been a trend-setting innovator of label software and
                            barcode generator software since 1985.
                                They developed the world's first Windows software for industrial label printers, including the very
                                first Windows printer drivers. Today, Seagull distributes more than 2,800 drivers for label and
                                other specialty printers.</p>
                        <p><br /></p>
                        <p>In fact, printer manufacturers the world over, even send their pre-release prototype printers to
                            Seagull to make sure the best drivers are available when the printers are released. BarTender is an
                            essential tool for hundreds of thousands
                                of users in more than 150 countries.</p>
                        <p><br /></p>
                        <p>Yes, BarTender is Danby's favorite label design tool but it's so much more. You can also
                            design and print smart cards, encode RFID compliance labels and control CO2 lasers for name and data
                            plates. Danby has BarTender experts
                                who can fully support any version of BarTender. Running stand-alone or integrated with just about
                                any other program,&nbsp;</p>
                        <p><br /></p>
                        </div>
                        <div className="col-xs-12 col-md-6 lead">
                        <p><span style={{ lineHeight: '1.5' }}>BarTender is the perfect solution for almost any on-demand printing
                                    or marking application, including:</span><br /></p>
                        <p><br /></p>
                        <ul>
                            <li>Labels, Tags and Cards</li>
                            <li>Print and apply</li>
                            <li>Direct marking on parts and packaging</li>
                            <li>Encoding smart cards</li>
                            <li>Sign production</li>
                        </ul>
                        <p><br /></p>
                        <p>Powerful companion applications even manage system security, network print functions, document
                            publishing, print job logging, and more. With four editions delivering a remarkable range of
                            features, there's a BarTender to satisfy every
                            need and budget.</p>
                        <p><br /></p>
                        <h5><strong>Resources</strong></h5>
                        <p><span style={{ fontSize: '1.07rem', lineHeight: '1.5' }}><br /></span></p>
                        <p><a href="docs/BarTender 2021, Data Sheet.pdf">BarTender 2021, Data Sheet</a></p>
                        <p><a href="docs/BarTender Features Comparison Chart, Automation Enterprise Editions.pdf">BarTender
                                        Features Comparison Chart, Automation Enterprise Editions</a></p>
                        <p><a href="docs/BarTender Features Comparison Chart, Pro Version and Starter Edition.pdf">BarTender
                                        Features Comparison Chart, Pro Version and Starter Edition</a></p>
                        <p><a href="docs/Label Design Printing Made Simple, An All-in-One Solution for Zebra Users.pdf">Label
                                        Design Printing Made Simple, An All-in-One Solution for Zebra Users</a></p>
                        <p><a href="docs/Medical Device Marking, Streamlined Secure.pdf">Medical Device Marking, Streamlined
                                        Secure</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Software;
