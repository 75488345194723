function getElementOffset(element) {
    let top = 0
    do {
        top += element.offsetTop  || 0;
        element = element.offsetParent;
    } while(element);

    return top;
};

function checkIfInView(animateIds) {
    const window_height = window.innerHeight;
    const window_top_position = document.documentElement.scrollTop || document.body.scrollTop;
    const window_bottom_position = window_top_position + window_height - 50;

    animateIds.forEach((el) => {
        const element = document.getElementById(el.divid);
        const element_height = element.offsetHeight;
        const element_top_position = getElementOffset(element);
        const element_bottom_position = (element_top_position + element_height);

        // check to see if this current element is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position) &&
            (element.classList.contains('hidden'))){
                el.fn(true);
        };
    });
}

export default checkIfInView;