import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

  
  const FeatureCard = ({ imgSrc, title }) => (
    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
      <div className="container">
        <div className="card card-block">
          <div className="card-img">
            <img src={imgSrc} className="card-img-top" alt={title} title={title} />
          </div>
          <div className="card-block">
            <h5 className="card-subtitle">{title}</h5>
          </div>
        </div>
      </div>
    </div>
  );

const Laminators = (onScrollEvent) => {
    const [vslam1InView, setVslam1InView] = useState(false);
    const [vslam2InView, setVslam2InView] = useState(false);
    const [vslam3InView, setVslam3InView] = useState(false);
    const [vslam4InView, setVslam4InView] = useState(false);
    const [vslam5InView, setVslam5InView] = useState(false);

    const animateIds = [{divid:'vslam1', fn:setVslam1InView}, {divid:'vslam2', fn:setVslam2InView}, {divid:'vslam3',fn:setVslam3InView}, {divid:'vslam4', fn:setVslam4InView},{divid:'vslam5',fn:setVslam5InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>
            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0" style={{ backgroundColor: 'rgb(67, 67, 67)', paddingTop: '120px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vslam1InView ? "animated fadeInUp row" : "hidden row"} id="vslam1">
                        <div className="col-xs-12">
                            <h3 className="mbr-section-title display-2"><p>Laminators</p></h3>
                            <small className="mbr-section-subtitle"> Custom, laminated product labels on demand</small>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-section article mbr-section__container" id="content1-0" style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vslam2InView ? "animated fadeInUp row" : "hidden row"} id="vslam2">
                        <div className="col-xs-12 lead">
                            <p>Some of our customers' label applications require an extreme measure of protection from harsh chemicals and environmental conditions. For years, leading manufacturers of automobiles, compressors, and electric motors have used Danby's DG-110 for "bullet-proof" labels that will last the life of their product. Our tough poly laminates can withstand harsh chemicals, dirt, ultraviolet rays, and extreme temperatures, but never compromise on aesthetics - your product name plates and VIN labels are part of your company image – make them last as long as your product!&nbsp;</p>
                            <p><a href="docs/DG-110_Spec_Sheet.pdf" className="text-danger"><strong>Download the DG-110 Datasheet</strong></a></p>
                            <p><br /></p>
                            <p><br /></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-section" id="laminators-msg-box4-0" style={{ backgroundColor: 'rgb(242, 242, 242)', paddingTop: '120px', paddingBottom: '120px' }}>
                <div className="container">
                    <div className={vslam3InView ? "animated fadeInUp row" : "hidden row"} id="vslam3">
                        <div className="mbr-table-md-up">
                            <div className="mbr-table-cell mbr-right-padding-md-up col-md-5 text-xs-center text-md-right">
                            <h3 className="mbr-section-title display-2">The DG-110 Laminator & Die-cutter</h3>
                            <div className="lead">
                                <p><span style={{ fontSize: '1.07rem', lineHeight: '1.5' }}>Play the video for a virtual demonstration of the DG-110 Laminator and Die-cutter</span><br /></p>
                            </div>
                            </div>
                            <div className="mbr-table-cell mbr-valign-top col-md-7">
                                <div className="mbr-figure">
                                    <iframe
                                    className="mbr-embedded-video"
                                    src="https://www.youtube.com/embed/PrLwytKBsYE?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0"
                                    width="1280"
                                    height="720"
                                    frameBorder="0"
                                    allowFullScreen
                                    title="DG-110 Laminator Video"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-0" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
                <div className={vslam4InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} id="vslam4">
                    <FeatureCard imgSrc="assets/images/1-600x600-32.jpg" title="DG-110 Laminator/Die-cutter" />
                    <FeatureCard imgSrc="assets/images/5-600x600-55.jpg" title="DG-110 with Workstation" />
                    <FeatureCard imgSrc="assets/images/3-600x600-91.jpg" title="DG-110 Rewind Station w/ Waste Strip" />
                </div>
            </section>
            <section className="mbr-section article mbr-section__container" id="laminators-content6-0" style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vslam5InView ? "animated fadeInUp row" : "hidden row"} id="vslam5">
                        <div className="col-xs-12 col-md-6 lead">
                            <p>Our full system consists of the following components:</p>
                            <p><br /></p>
                            <ol>
                            <li>The newly re-designed DG-110 Laminator/die-cutter unit</li>
                            <li>Easy touchscreen for adjustment controls</li>
                            <li>Thermal transfer printer with 200, 300, or 600 dpi print resolution</li>
                            <li>Customized, stream-lined work station, optional</li>
                            <li>PC-driven label design software: BarTender, by Seagull Scientific</li>
                            </ol>
                            <p><br /></p>
                            <p>The flexible label production system is not only impressive because of its simple operation, Swiss-engineering, and low maintenance, but the precision steel-rule cutting dies allow a variety of creative shapes that provide beautiful labels for a professional look. Text, graphics, barcodes, reverse print, boxes, and lines are possible, as well as sequential numbering. Text can be rotated to print at 90, 180, or 270 degrees. A variety of custom-designed label formats and shapes are possible.</p>
                        </div>
                        <div className="col-xs-12 col-md-6 lead">
                            <p>Polyester facestocks are the most popular for printing; however, economical paper facestock can be used as well for some applications. The label is covered with a transparent polyester film to protect it from abrasion, water, chemicals, and the effect of ultraviolet rays. After die-cutting and removal of the waste matrix, the printed and laminated labels are rewound into finished rolls, ready for application.</p>
                            <p><br /></p>
                            <h3>Resources</h3>
                            <p><a href="docs/Laminator_Case_Study.pdf">CaseStudy DG-110 Auto-maker, VIN Label System</a></p>
                            <p><a href="docs/Options_in_Laminated_Labels_Article.pdf">Options in Laminated Labels Article</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Laminators;
