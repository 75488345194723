import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

function Tharoprinters({onScrollEvent}) {

    const [vstsc1InView, setVstsc1InView] = useState(false);
    const [vstsc2InView, setVstsc2InView] = useState(false);
    const [vstsc3InView, setVstsc3InView] = useState(false);
    const [vstsc4InView, setVstsc4InView] = useState(false);
    const [vstsc5InView, setVstsc5InView] = useState(false);

    const animateIds = [{divid:'vstsc1', fn:setVstsc1InView}, {divid:'vstsc2', fn:setVstsc2InView}, {divid:'vstsc3',fn:setVstsc3InView}, {divid:'vstsc4', fn:setVstsc4InView},{divid:'vstsc5',fn:setVstsc5InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>

            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0"
                style={{backgroundColor: "rgb(67, 67, 67)", paddingTop: "120px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className={vstsc1InView ? "animated fadeInUp row" : "hidden row"} id="vstsc1">
                        <div className="col-xs-12">
                            <h3 className="mbr-section-title display-2">Tharo</h3>
                            <small className="mbr-section-subtitle"> Label Printers</small>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content1-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vstsc2InView ? "animated fadeInUp row" : "hidden row"} id="vstsc2">
                        <div className="row">
                            <div className="col-xs-9 lead">
                                <p>Tharo printers are thermal transfer / thermal direct label printers. Available in 4” and 6” label width sizes.
                                </p>
                                <p><br/></p>
                                <p><strong>Tharo Systems, Inc.</strong> has been a world leader in the Automatic Identification Industry since 1982. Tharo Systems’ products are being used by hundreds of thousands of companies worldwide. We hope that you will join us as one of our satisfied customers.</p>
                                <p><br/></p>
                                <p>Tharo Systems is the author of <strong>EASYLABEL</strong>, a time-tested label design software used by companies all over the world to design and print labels.  For over 40 years it has helped customers to quickly and easily create simple shipping labels as well as complex compliance labels.</p><br></br>
                            </div>
                            <div className='col-xs-3' style={{backgroundColor: "rgb(0,0,0)"}}>
                                <img className='img-fluid' style={{width: "310px", height: "247px", objectFit:"contain"}} src="assets/images/tharowhite.png" alt="Tharo Logo" />
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="row">
                            <div className="col-xs-12" style={{textAlign: "center", color: "rgb(255, 255, 255)", backgroundColor: "rgb(162, 23, 16)", fontSize: "1.5rem", minHeight: "5rem", display: "grid", alignItems: "center"}}>
                                <strong>FREE EASYLABEL START label design software included with every printer purchase!</strong>
                            </div>
                        </div>
                        <p><br /></p>
                        <hr style={{backgroundColor: "rgba(0,0,0,0.13)"}}></hr>
                    </div>
                </div>

            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-0"
                style={{backgroundColor: "rgb(255, 255, 255)"}}>
                <div className={vstsc3InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} style={{padding: "0 15% 0 15%"}} id="vstsc3">
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-block">
                                        <div className="card-img"><img src="assets/images/img-hserie_orig.png"
                                                className="card-img-top" alt='Tharo H-Series'/></div>
                                        <div className="card-block">
                                            <h2 className="card-title">Tharo H-Series</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <p>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Tharo H-Series 
                                        The Tharo H-Series label printers have an elegant design and are robust enough for commercial use. 
                                        They feature a durable, all metal construction with a cast Aluminum center wall and a control panel 
                                        that features a color LCD.</span></font>
                                    </p>
                                    <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                                    <ul>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>H-427+ prints 
                                            4″ wide at 203 dpi and up to 7 ips.</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>H-435+ prints 
                                            4″ wide at 300 dpi and up to 5 ips</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>H-627+ prints 
                                            6.6″ wide at 203 dpi and up to 7 ips.</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>H-635+ prints 
                                            6.6″ wide at 300 dpi and up to 5 ips.</span></font>
                                        </li>
                                    </ul>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <p>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>
                                            <strong>H-Series Accessories</strong></span></font>
                                        </p>
                                    </div>
                                    <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                                    <ul>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>H-Series Cutter</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>H-Series Internal 
                                            Rewinder* *label dispenser sensor included</span></font>
                                        </li>
                                    </ul>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-block">
                                        <div className="card-img"><img src="assets/images/tseries0_orig.png" className="card-img-top" alt='Tharo T-Series'/>
                                        </div>
                                        <div className="card-block">
                                            <h2 className="card-title">Tharo T-Series</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <p>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>The Tharo T-Series 
                                        label printers have an elegant design, are extremely compact and are robust enough for commercial use. 
                                        They feature a durable, all metal construction with a cast Aluminum center wall and a large touchscreen 
                                        LCD for ease of operation.</span></font>
                                    </p>
                                    <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                                    <ul>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>T-4210 prints at 
                                            200 dpi and up to 10 ips.</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>T-4307 prints at 
                                            300 dpi and up to 7 ips.</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>T-4604 prints at 
                                            600 dpi and up to 4 ips.</span></font>
                                        </li>
                                    </ul>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <p>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>
                                            <strong>T-Series Accessories</strong></span></font>
                                        </p>
                                    </div>
                                    <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                                    <ul>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>T-Series Cutter</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>T-Series Internal 
                                            Rewinder* *label dispenser sensor included</span></font>
                                        </li>
                                    </ul>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-4" style={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-block">
                                        <div className="card-img"><img src="assets/images/vseries_orig.png"
                                                className="card-img-top" alt='Tharo V-400E Series'/></div>
                                        <div className="card-block">
                                            <h2 className="card-title">Tharo V-400E Series</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                <p>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>TThe Tharo V-400E 
                                        Series feature a small footprint to fit your work environment. Now with standard Ethernet.</span></font>
                                    </p>
                                    <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                                    <ul>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>V-425E prints 
                                            at 203 dpi and up to 5 ips.</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>V-434E prints 
                                            at 300 dpi and up to 4 ips.</span></font>
                                        </li>
                                    </ul>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <p>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>
                                            <strong>V-Series Accessories</strong></span></font>
                                        </p>
                                    </div>
                                    <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                                    <ul>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>V-Series Cutter</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>V-Series Label 
                                            Dispenser Sensor</span></font>
                                        </li>
                                        <li>
                                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>V-Series External
                                            Label Roll Holder</span></font>
                                        </li>
                                    </ul>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                    <div>
                                        <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                                        </font>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="content7-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>
                <div className={vstsc5InView ? "animated fadeInUp row" : "hidden row"} style={{padding: "0 15% 0 15%"}} id="vstsc5">
                    <div className="col-xs-12 col-md-6 lead">
                        <h5><strong>Tharo Printer Accessories</strong></h5>
                        <div>
                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                            </font>
                        </div>
                        <div>
                            <p>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>
                                <strong>Universal Tharo Accessories</strong></span></font>
                            </p>
                        </div>
                        <div><span style={{fontSize: "1.07rem", lineHeight: "1.5"}}><br/></span></div>
                        <ul>
                            <li>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Tharo R-412 Rewinder</span></font>
                            </li>
                            <li>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>EASYWINDER Universal 
                                Label Rewind or Unwind</span></font>
                            </li>
                            <li>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Parallel Port</span></font>
                            </li>
                            <li>
                                <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Applicator Interface</span></font>
                            </li>
                        </ul>
                        <div>
                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                            </font>
                        </div>
                        <div>
                            <font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}><br/></span>
                            </font>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-cards mbr-section mbr-section-nopadding" id="features3-1"
                style={{backgroundColor: "rgb(255, 255, 255)"}}>
                <div className={vstsc4InView ? "animated fadeInUp mbr-cards-row row" : "hidden mbr-cards-row row"} style={{padding: "0 15% 0 15%"}} id="vstsc4">
                    <div className="mbr-cards-col col-xs-12 col-lg-3" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/r412-view12-3000x3000_orig.webp"
                                        className="card-img-top" alt='Tharo R-412 Rewinder'/></div>
                                <div className="card-block" style={{textAlign: 'left'}}>

                                    <h5 className="card-title">Tharo R-412 Rewinder</h5>
                                    <p><font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>The rewinder everyone can use. The R-412 
                                    is fast, affordable, easy to use and portable.</span></font></p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-3" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/easywinder1_orig.png" className="card-img-top" alt='EASYWINDER Universal Label Rewind or Unwind'/>
                                </div>
                                <div className="card-block" style={{textAlign: 'left'}}>

                                    <h5 className="card-title">EASYWINDER<br/>Universal Label Rewind or Unwind</h5>
                                    <p><font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>Powered label rewind compatible with direct thermal/thermal 
                                    transfer printers with a web width capacity from 1” to 9” wide. Media Roll 
                                    outside diameter capacity of 12”. *Unwind unit also available.</span></font></p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-cards-col col-xs-12 col-lg-3" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/ez6250i-cutter_orig.webp"
                                        className="card-img-top" alt='H-Series Cutter'/></div>
                                <div className="card-block" style={{textAlign: 'left'}}>

                                    <h5 className="card-title">H-Series Cutter</h5>
                                    <p><font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>A cutter designed to work with Tharo H-Series printers.
                                    </span></font></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mbr-cards-col col-xs-12 col-lg-3" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                        <div className="container">
                            <div className="card card-block">
                                <div className="card-img"><img src="assets/images/ez2250i-internal-rewinder_orig.webp"
                                        className="card-img-top" alt='H-Series Internal Rewinder'/></div>
                                <div className="card-block" style={{textAlign: 'left'}}>

                                    <h5 className="card-title">H-Series Internal Rewinder</h5>
                                    <p><font face="Raleway, sans-serif"><span style={{fontSize: "17px", lineHeight: "25px"}}>An internal rewinder designed to work with Tharo 
                                    H-Series printers.</span></font></p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <section className="mbr-section article mbr-section__container" id="content7-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className='row lead'>
                        <div className="col-xs-12">
                            <a href="docs/Danby-Cleaning_your_Industrial_Printer.pdf"><sup>*</sup>How to clean your industrial printer</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tharoprinters;