import React, { useEffect, useState } from 'react';
import checkIfInView from '../utils/checkInView';

const Easylabel = (onScrollEvent) => {
    const [vssf1InView, setVssf1InView] = useState(false);
    const [vssf2InView, setVssf2InView] = useState(false);
    const [vssf3InView, setVssf3InView] = useState(false);
    const [vssf4InView, setVssf4InView] = useState(false);
    const [vssf5InView, setVssf5InView] = useState(false);

    const animateIds = [{divid:'vssf1', fn:setVssf1InView}, {divid:'vssf2', fn:setVssf2InView}, {divid:'vssf3',fn:setVssf3InView}, {divid:'vssf4',fn:setVssf4InView}, {divid:'vssf5',fn:setVssf5InView}];
    useEffect(() => {
        checkIfInView(animateIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onScrollEvent])
    return (
        <>
            <section className="mbr-section mbr-section__container mbr-after-navbar" id="header3-0"
                style={{ backgroundColor: 'rgb(67, 67, 67)', paddingTop: '120px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf1InView ? "animated fadeInUp row" : "hidden row"} id="vssf1">
                        <div className="col-xs-12">
                        <h3 className="mbr-section-title display-2">EasyLabel</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="page10-content1-0"
                style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf2InView ? "animated fadeInUp row" : "hidden row"} id="vssf2">
                        <div className="col-xs-12 lead">
                            <center><img src="assets/images/logoeasylabel_orig.png" style={{width: '360px'}} alt="EasyLabel Logo" /></center>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="software-content7-0"
                style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf3InView ? "animated fadeInUp row" : "hidden row"} id="vssf3">
                        <div className="col-xs-12 col-md-6 lead">
                        <h5><span style={{ color: 'inherit', fontSize: '1.25rem', lineHeight: '1.1' }}>Why EasyLabel?</span><br />
                        </h5>
                        {/* <p><strong>Seagull Scientific's BarTender Software - the World's Most Trusted Labeling, Bar Code, and
                                    RFID Design Software</strong></p> */}
                        <p>EASYLABEL is a time-tested label design software used by companies all over the world to design and print labels.  For over 40 years it has helped our customers to quickly and easily create simple shipping labels as well as complex compliance labels.</p>
                        <p><br /></p>
                        <p><strong>All <a href='/tharoprinters'>Tharo printers</a> come with the FREE EASYLABEL START label design software!</strong></p>
                        <p><br /></p>
                        </div>
                        <div className="col-xs-12 col-md-6 lead">
                            <p><span style={{ lineHeight: '1.5' }}>EasyLable Features:</span><br /></p>
                            <p><br /></p>
                            <ul>
                                <li>Free Support</li>
                                <li>No Contracts</li>
                                <li>Easy Layout similar to Microsoft Office</li>
                                <li>Barcode Wizard</li>
                                <li>Database Connectivity</li>
                                <li>Integration with third party software and devices</li>
                                <li>Print History Backup and Reports</li>
                                <li>User Access Control</li>
                                <li>Print Automation</li>
                                <li>ActiveX Controls</li>
                                <li>XML Support</li>
                                <li>RFID Wizard</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mbr-section article mbr-section__container" id="software-content7-0"
                style={{ backgroundColor: 'rgb(255, 255, 255)', paddingTop: '20px', paddingBottom: '20px' }}>
                <div className="container">
                    <div className={vssf4InView ? "animated fadeInUp row" : "hidden row"} style={{textAlign: 'center'}} id="vssf4">
                        <div className="col-xs-12 col-md-6 lead">
                        <h5><span style={{ color: 'inherit', fontSize: '1.25rem', lineHeight: '1.1' }}>License Dongle vs Digital License</span><br />
                        </h5>
                        <p><br /></p>
                        <div style={{textAlign:'left'}}>
                        <p><strong>License Dongle</strong></p>
                            <ul>
                                <li>Physical dongle that connects to a USB port</li>
                                <li>Same license dongle used by EASYLABEL 5 and 6</li>
                                <li>Needs to be connected to the computer running EASYLABEL or acting as a license server</li>
                                <li>Can easily be transferred to another computer</li>
                            </ul>
                        <p><br /></p>
                        <p><strong>Digital License</strong></p>
                            <ul>
                                <li>Does not require a physical dongle</li>
                                <li>Ideal for laptops, virtual machines or any environment where a dongle might be lost or damaged</li>
                                <li>Created for a specific computer running EASYLABEL or acting as a license server</li>
                                <li>Cannot be recovered if Windows does not load</li>
                                <li>Moving / copying a virtual machine or booting a snapshot will invalidate its license</li>
                                <li>Can only be transferred by EASYLABEL support (additional fee applies)</li>
                            </ul>
                        </div>
                        <p><br /></p>
                        </div>
                        <div className="col-xs-12 col-md-6 lead">
                            <p><span style={{ color: 'inherit', fontSize: '1.25rem', lineHeight: '1.1' }}>License Methods</span><br /></p>
                            <p><br /></p>
                            <div style={{textAlign:'left'}}>
                                <strong>
                                    <span>
                                        <span>
                                            <font >Single-User<font size="2"><br/>See Level Comparison for Pricing and Features<br/></font></font>
                                        </span>
                                    </span>
                                </strong>
                                <ul>
                                    <li>License is connected to the computer running EASYLABEL</li>
                                    <li>Available as SILVER, GOLD and PLATINUM levels</li>
                                    <li>No network connection required</li>
                                    <li>Will not run Windows Terminal Server</li>
                                </ul>
                                <br/>
                                <strong>
                                    <span>
                                        <span><font >Multi-User</font>
                                        </span>
                                    </span>
                                </strong>
                                <br/>
                                <font  size="2"><strong>LIST Starting at $1,495.00</strong></font>
                                <ul>
                                    <li>One license is connected to the computer acting as the EASYLABEL license server</li>
                                    <li>EASYLABEL is installed on local computers and receives a license over the network from the license server</li>
                                    <li>Will not run Windows Terminal Server</li>
                                </ul>
                                <br/>
                                <strong>
                                    <span>
                                        <span>
                                            <font >Terminal Server</font>
                                        </span>
                                    </span>
                                </strong>
                                <br/>
                                <font  size="2"><strong>LIST $2,995.00</strong></font>
                                <ul>
                                    <li>Installs on a single Windows Terminal Server</li>
                                    <li>Clients log into the Terminal Server to use EASYLABEL</li>
                                    <li>Requires one license per server</li>
                                </ul>
                                <br/>
                                <strong>
                                    <span>
                                        <span>
                                            <font >Net Print Server</font>
                                        </span>
                                    </span>
                                </strong>
                                <br/>
                                <font  size="2"><strong>LIST Starting at $2,995.00</strong></font>
                                <ul>
                                    <li>Installs on a single computer acting as the web server</li>
                                    <li>Clients log into a website to select and print labels</li>
                                    <li>Clients cannot create or edit labels</li>
                                </ul>
                            </div>
                        </div>
                        <a className="btn btn-primary" style={{marginTop: '20px'}} href="https://easylabel.com/level-comparison/" target="_blank" rel="noreferrer">
                            <span>Learn More</span>
                        </a>
                        <hr style={{backgroundColor: "rgba(0, 0, 0, 0.13)"}}></hr>
                    </div>
                </div>
            </section>
            <section className="mbr-section article mbr-section__container" id="content1-0"
                style={{backgroundColor: "rgb(255, 255, 255)", paddingTop: "20px", paddingBottom: "20px"}}>

                <div className="container">
                    <div className={vssf5InView ? "animated fadeInUp row" : "hidden row"} id="vssf5">
                        <div className="row">
                            <div className="col-xs-12" style={{textAlign: "center", color: "rgb(255, 255, 255)", backgroundColor: "rgb(162, 23, 16)", fontSize: "1.5rem", minHeight: "5rem", display: "grid", alignItems: "center"}}>
                                <strong>Single User Level Comparison</strong>
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="row">
                            <div className="col-xs-3 lead">
                                <div style={{textAlign: 'left'}}>
                                    <font color="#8d2424" size="5">
                                        <span><strong>START </strong></span>
                                    </font>
                                    <br/>
                                    <strong><font color="#8d2424">FREE with any <a href="https://www.covingtonbarcoding.com/tharo-printers.html" target="_blank" rel="noreferrer">Tharo Printer</a></font></strong><br/><font color="#a82e2e"><em>START is compatible with <u>Tharo</u> printers ONLY</em></font><ul><li>Support of 40 types of Graphics such as PCX, PCC, EPS, WMF, BMP, JPG and TIF</li><li>Basic Time and Date as Field Data</li><li>Edit Order of User-input Prompts</li><li>Custom Text Formatting</li><li>Selectable Check Digits</li><li>25 Maximum number of fields on a Format</li><li>Print Queue</li><li>Stage Multiple Print Jobs in Print Queue</li><li>Enable / Disable Printing of Specific Fields</li><li>Print to Multiple Printers from one PC</li><li>Password Security</li><li>Basic Time and Data Functions</li></ul></div>
                            </div>
                            <div className='col-xs-3'>
                                <div style={{textAlign:"left"}}><font color="#8d2424" size="5"><strong>SILVER</strong></font><br/><font color="#8d2424"><strong>LIST $245.00</strong></font><br/>All of the features of START plus...<ul><li>Memory Card Download</li><li>Consecutive Numbering Text &amp; Bar Codes</li><li>Support for Paragraph Fields</li><li>Pass Through Function</li><li>Support for Windows Print Drivers</li><li>Print Color Text and Graphics on Color Printers</li></ul></div>
                            </div>
                            <div className='col-xs-3'>
                                <div style={{textAlign:"left"}}><font color="#8d2424" size="5"><strong>GOLD <br/></strong></font><font color="#8d2424"><strong>LIST $545.00</strong></font><font color="#8d2424" size="5"></font><br/>All of the features of SILVER plus...<ul><li>ActiveX® Data Objects (ADO)</li><li>OLE DB Based database editor</li><li>Print a Format to a File</li><li>Import Data from External Data Sources</li><li><em>Advanced</em> Time and Date as Field Data</li><li>Read Paragraph Data from a Database</li><li>Embed a Graphic in a Database Record</li><li>Read Bar Code/Text Data from a Database</li><li>Data Validation on User Input</li><li>EAN/UCC 128 Wizard</li><li>GS-1 Bar Code Wizards</li><li>Support for 2D and Composite Barcodes</li><li><em>Unlimited</em> Number of Fields on a Format</li><li>Ability to Link Fields</li><li>Add-on characters for Bar Codes</li><li>Special Formatting of Fields</li><li>Slanted, Arced for Text Fields</li><li>Field Fill Function</li><li>Allow Blanks for Operator Input Fields</li><li>Internal Database</li><li>Embed Serial Number in Database Record</li><li>Conditional Printing of Database Records</li><li>Print Items with Common Values</li><li>Save Print Queue Upon Exit EASYLABEL</li><li>Mirror Image Printing</li><li>Serial Files</li><li>Arithmetic Functions</li><li>Advanced Time and Date Functions</li><li>Voice Code</li></ul></div>
                            </div>
                            <div className="col-xs-3 lead">
                                <div style={{textAlign:"left"}}><font color="#8d2424" size="5"><strong>PLATINUM </strong></font><br/><font color="#8d2424"><strong>LIST $995.00</strong></font><br/>All of the features of GOLD plus...<br/><strong><em>XML and Print Only Key are PLATINUM <u>exclusive</u> features</em></strong><ul><li>Command File Monitoring/Processing</li><li>ActiveX® Controls</li><li>XML Monitor</li><li>XML Generator</li><li>Job List File</li><li>Labelcom</li><li>RFID Wizard</li><li>Print History Console</li><li>Tools for FDA 21 CFR Part 11 Compliance</li><li>Track Data Field by Field for a Format</li><li>Cross Reference function to link fields from multiple databases together</li><li>List All Format Specs to Laser/Dot Matrix Printer</li><li>Support for Western Telematic Switch Boxes</li><li>Print Only Version (separate license purchase available)</li></ul></div>
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="row">
                            <div className="col-xs-12" style={{textAlign: "center", color: "rgb(255, 255, 255)", backgroundColor: "rgb(162, 23, 16)", fontSize: "1.5rem", minHeight: "5rem", display: "grid", alignItems: "center"}}>
                                <strong>Easy Label Documentation</strong>
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="row" style={{textAlign: 'center'}}>
                            <div className="col-xs-6">
                                <a href="https://easylabel.com/getting-started/" target='_blank' className="btn btn-primary" rel="noreferrer">GETTING STARTED WITH EASYLABEL</a>
                            </div>
                            <div className="col-xs-6">
                                <a href="https://easylabel.com/getting-started/" target='_blank' className="btn btn-primary" rel="noreferrer">LEARN WITH EASYLABEL TUTORIALS</a>
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="row" style={{textAlign: 'center'}}>
                            <div className="col-xs-6">
                                <a href="https://easylabel.com/newest-easylabel/" target='_blank' className="btn btn-primary" rel="noreferrer">DOWNLOAD EASYLABEL SOFTWARE</a>
                            </div>
                            <div className="col-xs-6">
                                <a href="https://easylabel.com/drivers/" target='_blank' className="btn btn-primary" rel="noreferrer">DOWNLOAD YOUR PRINTER DRIVERS</a>
                            </div>
                        </div>
                        <p><br /></p>
                        <div className="col-xs-4 lead">
                            <span>
                                <strong>
                                    <font size="5">GUIDES</font>
                                </strong>
                            </span>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Installing-EASYLABEL.pdf" target="_blank" rel="noreferrer">
                                <span>Installing EASYLABEL (First installation guide)</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Multi-User-Install-Guide.pdf" target="_blank" rel="noreferrer">
                                <span>Multi-User Install Guide</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Moving-EASYLABEL.pdf" target="_blank" rel="noreferrer"><
                                span>Moving EASYLABEL</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/ELNPS_instructions.pdf" target="_blank" rel="noreferrer">
                                <span>Installing EASYLABEL Net Print Server</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/ODBC-Connection-Guide.pdf" target="_blank" rel="noreferrer">
                                <span>ODBC Database Connection Guide</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Foil-Label-Adjustments-H627+.pdf" target="_blank" rel="noreferrer">
                                <span>H-627+ Pharmacy Foil Label Adjustments</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/EASYLABEL_SQL.pdf" target="_blank" rel="noreferrer">
                                <span>Using EASYLABEL with SQL Server</span>
                            </a>
                            <br/><a href="https://easylabel.com/documents/software/EASYLABEL-Print-History-MDB.pdf" target="_blank" rel="noreferrer">
                                <span>Print History Console Using MDB</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/EASYLABEL-Print-History-SQL.pdf" target="_blank" rel="noreferrer">
                                <span>Print History Console Using SQL</span>
                            </a>
                        </div>
                        <div className="col-xs-4 lead">
                            <span>
                                <strong>
                                    <font size="5">GENERAL </font>
                                </strong>
                            </span>
                            <br />
                            <a href="https://easylabel.com/documents/software/EASYLABEL-Feature-Comparison-Table.pdf" target="_blank" rel="noreferrer">
                                <span>EASYLABEL Feature Comparison Table</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/EASYLABEL-Troubleshooting-PCS.pdf" target="_blank" rel="noreferrer">
                                <span>EASYLABEL Troubleshooting PCS</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Easylabel-Help-Printable.pdf" target="_blank" rel="noreferrer">
                                <span>EASYLABEL Printable Documentation</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Bar-Codes-and-RFID-Oracle.pdf" target="_blank" rel="noreferrer">
                                <span>Barcodes and RFID using Oracle</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Bar-Codes-and-RFID-SAP.pdf" target="_blank" rel="noreferrer">
                                <span>Barcodes and RFID using SAP</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Bar-Codes-and-RFID-XML.pdf" target="_blank" rel="noreferrer">
                                <span>Barcodes and RFID using XML</span></a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/ELNPS_overview.pdf" target="_blank" rel="noreferrer">
                                <span>Net Print Server Overview</span></a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/EL_21CFR_Part11.pdf" target="_blank" rel="noreferrer">
                                <span>FDA 21 CFR Part 11</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Multi-User-Terminal-Server-Net-Print-Server.pdf" target="_blank" rel="noreferrer">
                                <span>Network License Comparison</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/Non-WK-Errors.pdf" target="_blank" rel="noreferrer">
                                <span>Non WK Errors and Solutions</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/RFID_overview.pdf" target="_blank" rel="noreferrer">
                                <span>RFID Overview</span>
                            </a>
                            <br/><a href="https://easylabel.com/documents/software/standalone-printing.pdf" target="_blank" rel="noreferrer">
                                <span>Standalone Printing</span>
                            </a>
                            <br/>
                            <a href="https://easylabel.com/documents/software/system_integration.pdf" target="_blank" rel="noreferrer"><
                                span>System Integration</span>
                            </a>
                            <br/><a href="https://easylabel.com/documents/software/Unicode-EASYLABEL.pdf" target="_blank" rel="noreferrer"><span>Unicode</span> </a><br/><a href="https://easylabel.com/documents/software/VoiceCode.pdf" target="_blank" rel="noreferrer"><span>VoiceCode</span> </a><br/><a href="https://easylabel.com/documents/software/walmart-rfid-overview.pdf" target="_blank" rel="noreferrer"><span>EASYLABEL Wal-Mart RFID Labeling</span> </a>
                        </div>
                        <div className="col-xs-4 lead">
                            <span>
                                <strong>
                                    <font size="5">CASE STUDIES</font>
                                </strong>
                            </span><br/><a href="https://easylabel.com/documents/software/case-labelcom-scale.pdf" target="_blank" rel="noreferrer"><span>Connecting EASYLABEL with a Scale</span> </a><br/><a href="https://easylabel.com/documents/software/case-standalone.pdf" target="_blank" rel="noreferrer"><span>Printing Unique Labels without a PC (Standalone Printing)</span> </a><br/></div>
                        <p><br /></p>
                        <hr style={{backgroundColor: "rgba(0,0,0,0.13)"}}></hr>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Easylabel;
