import React from 'react';

function Menu() {

    return (
        <>
            <section id="menu-0">

                <nav className="navbar navbar-dropdown navbar-fixed-top">
                    <div className="container">

                        <div className="mbr-table">
                            <div className="mbr-table-cell">

                                <div className="navbar-brand">
                                    <a href="https://www.danbygroup.com" className="navbar-logo">
                                        <img src="assets/images/DanbyLogo.png" alt="Danby Barcoding"/>
                                    </a>
                                </div>

                            </div>
                            <div className="mbr-table-cell">
                                <button className="navbar-toggler hidden-md-up" type="button" data-toggle="collapse" data-target="#exCollapsingNavbar">
                                <div className="hamburger-icon"></div>
                                </button>

                                <ul className="nav-dropdown collapse nav navbar-nav navbar-toggleable-sm"
                                    id="exCollapsingNavbar">
                                    <li className="nav-item">
                                        <a className="nav-link link" href="/">HOME</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link link" href="/label">LABELING</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link link dropdown-toggle" href="/" aria-expanded="false"
                                            data-toggle="dropdown-submenu">PRINTERS</a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="/zebraprinters">Zebra Printers</a>
                                            <a className="dropdown-item" href="/tscprinters">TSC Printers</a>
                                            <a className="dropdown-item" href="/tharoprinters">Tharo Printers</a>
                                            <a href="/epsonprinters" className="dropdown-item">Epson Colorworks Printers</a>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link link" href="/laminators">LAMINATORS</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link link dropdown-toggle" href="/" aria-expanded="false"
                                            data-toggle="dropdown-submenu">LABEL SOFTWARE</a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="/software">BarTender</a>
                                            <a className="dropdown-item" href="/easylabel">EasyLabel</a>
                                        </div>
                                    </li>
                                </ul>
                                <button hidden={true} className="navbar-toggler navbar-close" type="button" data-toggle="collapse"
                                    data-target="#exCollapsingNavbar">
                                    <div className="close-icon"></div>
                                </button>

                            </div>
                        </div>

                    </div>
                </nav>

            </section>
        </>
    )
}

export default Menu;